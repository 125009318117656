import React, { useRef } from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { OPEN_CONTACT_MODAL } from "../consts";
import Popup from "reactjs-popup";
import { X } from "react-feather";
import Button from "./atoms/Button";
import ContactForm from "../components/estimate-form";

const FeaturedColumns = (data) => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} index={index} item={item} columnsCount={data.data.length} />
  ));
  return dataColumnsCount;
};

const FeaturedColumn = (data) => {
  const learnMoreRef = useRef(null);
  const buttonProps = {};
  const hasContactModalClass =
    data.item.classNames &&
    data.item.classNames.length > 0 &&
    data.item.classNames.filter((className) => className == OPEN_CONTACT_MODAL).length > 0;
  if (hasContactModalClass) {
    buttonProps.ref = learnMoreRef;
    if (learnMoreRef.current) {
      // learnMoreRef.current.click = e => e.preventDefault()
    }
  }

  return (
    <div key={data.item.slug} className="flex  bg-white rounded-xl overflow-hidden items-center shadow-md "> 
      <GatsbyImage
        className={`w-1/2 ${data.index % 2 === 0 ? "order-2" : "order-1"}`}
        image={getImage(data.item.heroImage)}
        style={{  minHeight:'350px', height: "100%", objectFit: "cover" }}
        alt={data.item.serviceTitle + " Contractor"}
        
      />
      <div className={`bg-white w-1/2 flex-1 relative   px-6 py-12 md:px-8  ${data.index % 2 === 0 ? "order-1" : "order-2"}`}>
        <h3 className="text-xl lg:text-2xl font-bold text-neutral-900">
          {data.item.featuredLabel ? data.item.featuredLabel : data.item.serviceTitle}
        </h3>
        <p className="mt-4 text-base text-neutral-500">{data.item && data.item.shortExcerpt}</p>
        <div className="mt-6">
      
          <Button to={data.item.slug} title={"Learn more about " + data.item.serviceTitle}>
            Learn More
          </Button>
       
      </div>
      </div>
     
    </div>
  );
};

const FeaturedItems = ({ data }) => {
  return (
    <div className="bg-gradient-to-b from-neutral-50 to-neutral-100">
      <section className="max-w-7xl mx-auto relative py-12 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
        <h2 className="sr-only" id="contact-heading">
          Featured Services
        </h2>
        
        <div className="grid grid-cols-1 gap-y-4 lg:grid-cols-1 lg:gap-y-8 lg:gap-x-8">
          <FeaturedColumns data={data} />
        </div>
      </section>
    </div>
  );
};
export default FeaturedItems;
